import React, {
  FormEvent, useContext, useEffect, useState, memo,
} from 'react';
import spinner from '../../../assets/images/spinner.gif';
import { navigate } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import './my-profile.scss';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datepicker';
import {
  subYears, addDays, addYears, addMonths,
} from 'date-fns';
import ApplicationContext from '../../../state/ApplicationContext';
import Select from '../../Forms/Select';
import Input from '../../Forms/Input';
import lock from '../../../assets/images/lock_2x.png';
import Checkbox from '../../Forms/Checkbox';
import {
  checkCountry, getConfig, editUserSettings, editUserSettingsCheckboxes,
} from '../../../api';
import { getImageData, bytesToMegaBytes } from '../../../utils/ImageValidation';
import {
  firstNameValidate, notEmpty,
} from '../../../utils/validation';

function b64toBlob(dataURI) {
  if (!dataURI) return null;
  try {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    let type = 'image/jpeg';

    if (dataURI.includes('image/png')) {
      type = 'image/png';
    }
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  } catch (e) {
    return null;
  }
}

const titles = [
  {
    label: 'Mr.',
    value: 'Mr.',
  },
  {
    label: 'Mrs.',
    value: 'Mrs.',
  },
  {
    label: 'Ms.',
    value: 'Ms.',
  },
  {
    label: 'Miss.',
    value: 'Miss.',
  },
  {
    label: 'Dr.',
    value: 'Dr.',
  },
  {
    label: 'Sir',
    value: 'Sir',
  },
  {
    label: 'Lord',
    value: 'Lord',
  },
  {
    label: 'Lady',
    value: 'Lady',
  },

];

const temporaryOptions = [
  {
    label: '24 Hours',
    value: '24_hours',
  },
  {
    label: '48 Hours',
    value: '48_hours',
  },
  {
    label: '1 Month',
    value: '1_month',
  },
  {
    label: '3 Month',
    value: '3_month',
  },
  {
    label: '1 year',
    value: '1_year',
  },
];
const selfExclusion = [
  {
    label: '6 Months',
    value: '6_months',
  },
  {
    label: '1 Year',
    value: '1_year',
  },
];

let initialTopState = {};
let initialBottomState = {};

const Index = ({ currentPage, setCurrentPage, intl: { formatMessage, locale } }) => {
  const {
    user, handleGrowl, setUser, setResetPassword, setShowSignIn,
  } = useContext(ApplicationContext);

  if (!user) return null;
  const userToken = localStorage.getItem('userToken');

  if (!userToken) {
    navigate('/');
    setUser(null);
    localStorage.removeItem('userData');
    setShowSignIn(true);
    handleGrowl(true, formatMessage({ id: 'To view this page, you must log in to your account' }), false);
    return null;
  }
  const [street, setStreet] = useState(user?.streetName);
  const [numb, setNumb] = useState(user?.streetNumber);
  const [postCode, setPostCode] = useState(user?.postCode);
  const [city, setCity] = useState(user?.city);
  const [phone, setPhone] = useState(user?.phone);
  const [avatar, setAvatar] = useState(user?.avatar ? `${user?.avatar}_100x100.jpg` : null);
  const [timezones, setTimezones] = useState([]);
  const [userTimezone, setUserTimezone] = useState(user?.timezoneObj.text);

  const [adress, setAdress] = useState(user?.optionalAddress);
  const [deposit, setDeposit] = useState(String(user?.depositLimit) || '');
  const [depositLimit, setDepositLimit] = useState(false);
  const [emailNotification, setEmailNotification] = useState(user?.notifications);
  const [showAccountGaming, setShowAccountGaming] = useState(false);

  const [settingLoading, setSettingLoading] = useState(false);

  useEffect(() => {
    if(deposit && deposit !== '0'){
      setDepositLimit(true);
    }

    if(deposit === '0'){
      setDeposit('200')
    }
  }, [])

  const [status, setStatus] = useState(user?.status);
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState({
    code: user?.countryCode,
    name: user?.countryFullName,
  });
  const [title, setTitle] = useState({
    label: user?.title || 'Mr.',
    value: user?.title || 'Mr.',
  });

  const translate = (str) => (formatMessage({ id: str }));

  const [temporaryCurrent, setTemporaryCurrent] = useState({
    label: translate('24 Hours'),
    value: '24_hours',
  });

  const [selfCurrent, setSelfCurrent] = useState({
    label: translate('6 Months'),
    value: '6_months',
  });


  const temporaryOptionsTranslated = temporaryOptions.map((element) => ({
    ...element,
    label: translate(element.label)
  }));

  const selfExclusionTranslated = selfExclusion.map((element) => ({
    ...element,
    label: translate(element.label)
  }));

  const handleResetPassword = (event) => {
    event.preventDefault();
    setResetPassword(true);
  };

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage, setCurrentPage]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([checkCountry(), getConfig()]).then((promises) => {
      setCountry(promises[0].data.country);
      setCountries(promises[1].data.countries);
      console.log(promises[1].data.countries.find((element) => element.code === user?.countryCode))
      setCountryCode({
        code: user?.countryCode,
        name: promises[1].data.countries.find((element) => element.code === user?.countryCode).name,
      })
      setTimezones(promises[1].data.timezones);
      const timeZoneValue = promises[1].data.timezones.find((element) => element.id === user?.timezoneObj.id);
      if (timeZoneValue) {
        setUserTimezone({
          label: timeZoneValue.text,
          value: timeZoneValue.id,
        });
        initialTopState = {
          title: title.label,
          countryCode: countryCode.code,
          phone,
          userTimezone: timeZoneValue?.id,
          street,
          numb,
          city,
          postCode,
          adress,
          avatar,
        };
      }
      setLoading(false);
    });
    // getConfig().then((response) => {
    //   setCountries(response.data.countries);
    //   setTimezones(response.data.timezones);
    //
    //   const timeZoneValue = response.data.timezones.find((element) => element.id === user?.timezoneObj.id);
    //   if (timeZoneValue) {
    //     setUserTimezone({
    //       label: timeZoneValue.text,
    //       value: timeZoneValue.id,
    //     });
    //
    //     console.log(timeZoneValue.id)
    //     initialTopState = {
    //       title: title.label,
    //       countryCode: countryCode.code,
    //       phone,
    //       userTimezone: timeZoneValue.id,
    //       street,
    //       numb,
    //       city,
    //       postCode,
    //       adress,
    //       avatar,
    //     };
    //   }
    // });
    initialBottomState = {
      status,
      depositLimit,
      deposit,
      emailNotification,
    };
  }, [user]);
  const fileChangeListener = async (event) => {
    if (!event.currentTarget.files[0]) return;
    const mb = bytesToMegaBytes(event.currentTarget.files[0].size);
    if (mb > 5) {
      handleGrowl(true, translate('The image must not exceed 5 megabytes'), false);
      return;
    }
    try {
      const imgData = await getImageData(event.currentTarget.files[0]);
      if (imgData.width < 100 || imgData.height < 100 || imgData.error) {
        handleGrowl(true, translate('The image size must not be less than 100x100 pixels'), false);
        return;
      }
      setAvatar(imgData.source);
    } catch (error) {
      handleGrowl(true, translate('Please upload a valid image'), false);
    }
  };
  const handleCountryCodeChange = (value) => {
    setCountryCode({
      ...countryCode,
      code: value.value,
      shortName: value.value,
      name: value.label.replace(value.value, ''),
    });
  };
  const handleCountryChange = (value) => setCountry({
    ...country,
    shortName: value.value,
    name: value.label,
  });

  const handleUserTimezone = (value) => {
    setUserTimezone(value);
  };
  const options = countries.map((element) => ({
    label: element.name,
    value: element.shortName,
  }));
  const timezonesOptions = timezones.map((element) => ({
    label: element.text,
    value: element.id,
  }));
  const optionsPhone = countries.map((element) => ({
    label: `${element.code} ${element.name}`,
    value: element.code,
  }));
  const [errors, setErrors] = useState({
    street: '',
    number: '',
    postCode: '',
    city: '',
    phone: '',
    date: '',
    deposit: '',
    adress: '',
  });
  const validateField = (fieldName: string):void => {
    switch (fieldName) {
      case 'adress':
        if (!adress.trim().length) {
          setErrors({
            ...errors,
            adress: '',
          });
          return;
        }
        if (!firstNameValidate(adress) && !errors.adress.length) {
          setErrors({
            ...errors,
            adress: formatMessage({ id: 'Please enter your address.' }),
          });
        }
        if (firstNameValidate(adress) && errors.adress.length) {
          setErrors({
            ...errors,
            adress: '',
          });
        }
        break;
      case 'street':
        if (!firstNameValidate(street) && !errors.street.length) {
          setErrors({
            ...errors,
            street: formatMessage({ id: 'Please enter your street name.' }),
          });
        }
        if (firstNameValidate(street) && errors.street.length) {
          setErrors({
            ...errors,
            street: '',
          });
        }
        break;
      case 'number':
        if (numb.trim().length < 1 && !errors.number.length) {
          setErrors({
            ...errors,
            number: formatMessage({ id: 'Please enter the street number.' }),
          });
        }
        if (numb.trim().length > 1 && errors.number.length) {
          setErrors({
            ...errors,
            number: '',
          });
        }
        break;
      case 'city':
        if (!firstNameValidate(city) && !errors.city.length) {
          setErrors({
            ...errors,
            city: formatMessage({ id: 'Please enter your city.' }),
          });
        }
        if (firstNameValidate(city) && errors.city.length) {
          setErrors({
            ...errors,
            city: '',
          });
        }
        break;
      case 'postcode':
        if (!firstNameValidate(postCode) && !errors.postCode.length) {
          setErrors({
            ...errors,
            postCode: formatMessage({ id: 'Please enter your post code.' }),
          });
        }
        if (firstNameValidate(postCode) && errors.postCode.length) {
          setErrors({
            ...errors,
            postCode: '',
          });
        }
        break;
      case 'phone':
        if (!firstNameValidate(phone) && !errors.phone.length || phone.trim().length < 7) {
          setErrors({
            ...errors,
            phone: formatMessage({ id: 'Please enter your phone.' }),
          });
        }
        if (firstNameValidate(phone) && errors.phone.length) {
          setErrors({
            ...errors,
            phone: '',
          });
        }
        break;
      case 'deposit':
        if (!firstNameValidate(deposit) && !errors.deposit.length) {
          setErrors({
            ...errors,
            deposit: formatMessage({ id: 'Deposit limit required' }),
          });
        }
        if (firstNameValidate(deposit) && errors.deposit.length) {
          setErrors({
            ...errors,
            deposit: '',
          });
        }
        break;
      default:
        break;
    }
  };
  const handleSubmitAccount = (event: FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    const userData = {
      avatar,
      email: user.email,
      name: user.name,
      lname: user.lname,
      title: title.label,
      dob: user.dob,
      country: user.country,
      countryCode: countryCode?.code,
      phone,
      streetName: street,
      streetNumber: numb,
      postCode,
      city,
      status: user.status,
      language: locale,
      idMaster: null,
      idTimezone: userTimezone.value,
      idReferral: null,
      hadReferralDiscount: null,
      optionalAddress: adress || '',
    };
    Object.keys(userData).forEach((element) => {
      if (element === 'avatar') {
        const img = b64toBlob(userData[(element)]);
        if (img) {
          formData.append(element, img);
        }
      } else {
        formData.append(element, userData[element]);
      }
    });

    setLoading(true);
    editUserSettings(formData, user.id).then((response) => {
      setUser(response.data);
      localStorage.setItem('userData', JSON.stringify(response.data));
      handleGrowl(true, translate('Your profile has been successfully updated'), true);
      initialTopState = {
        title: title.label,
        countryCode: countryCode.code,
        phone,
        userTimezone: response.data.idTimezone,
        street,
        numb,
        city,
        postCode,
        adress,
        avatar,
      };
    }).finally(() => {
      setLoading(false);
    });
  };

  const isInvalid = () => {
    const errorsValues = Object.values(errors);
    return notEmpty(errorsValues);
  };

  const handleSettingSubmit = (event: FormEvent) => {
    event.preventDefault();

    setSettingLoading(true);
    const banMap = {
      '24_hours': addDays(new Date(), 1),
      '48_hours': addDays(new Date(), 2),
      '1_month': addMonths(new Date(), 1),
      '3_month': addMonths(new Date(), 3),
      '1_year': addYears(new Date(), 3),
    };

    const statusMap = {
      close: 0,
      registered: '10',
      temporary: '20',
      self: '30',
    };
    const userSettingsConfig: any = {
      status,
      notifications: emailNotification,
    };

    if (depositLimit) {
      userSettingsConfig.depositLimit = deposit;
    } else {
      userSettingsConfig.depositLimit = 0;
    }
    if (status > 10) {
      userSettingsConfig.dateBannedUntil = banMap[temporaryCurrent.value];
    }
    editUserSettingsCheckboxes(userSettingsConfig).then((response) => {
      setUser(response.data);
      localStorage.setItem('userData', JSON.stringify(response.data));
      handleGrowl(true, translate('Your profile has been successfully updated'), true);
      if (+status === 0 || +status === 20 || +status === 30) {
        navigate('/');
        setUser(null);
        localStorage.removeItem('userData');
      }
      initialBottomState = {
        status,
        depositLimit,
        deposit,
        emailNotification,
      };
    }).finally(() => {
      setSettingLoading(false);
    });
  };
  const currentState = {
    title: title.label,
    countryCode: countryCode.code,
    phone,
    userTimezone: userTimezone?.value,
    street,
    numb,
    city,
    postCode,
    adress,
    avatar,
  };
  const currentBottomState = {
    status,
    depositLimit,
    deposit,
    emailNotification,
  };
  const disableTopButton = JSON.stringify(currentState) === JSON.stringify(initialTopState);
  const disableBottomButton = JSON.stringify(currentBottomState) === JSON.stringify(initialBottomState);

  console.log(disableBottomButton, currentBottomState, initialBottomState)

  const titlesTranslated = titles.map((element) => ({
    ...element,
    label: translate(element.label),
  }));
  const CustomDate = ({
    value, onClick, onChange, placeholder,
  }) => (
    <Input
      labelName={translate('Date of birth*')}
      value={value}
      onClick={onClick}
      onChange={onChange}
      disabled
      placeholder={placeholder}
      name="date"
      date
    />
  );
  return (
    <div className="page-content">
      <Helmet>
        <title>
          Lotteroo | {translate('My Profile')}
        </title>
      </Helmet>
      <div className="profile-content">
        <div className="account-details-block">
          <form action="#" className="account-details form" onSubmit={handleSubmitAccount} autoComplete="off">
            <div className="heading-page">{translate('Account Details')}</div>
            <div className="form-content">
              <div className="avatar-block">
                <div className="img-wrap">
                  <img
                    src={avatar}
                    alt=""
                    className={classNames({
                      'no-image': !avatar,
                    })}
                  />
                </div>
                <div className="upload">
                  <label
                    htmlFor="avatar"
                  >
                    {translate('Upload Avatar')}
                  </label>
                  <input
                    id="avatar"
                    type="file"
                    accept="image/jpeg, image/jpg, image/png"
                    onChange={fileChangeListener}
                  />
                </div>
              </div>
              <div className="inputs-block">
                <div className="info-block">
                  <div className="basic-block">
                    <div className="title">
                      {translate('Basic Info')}
                      <span className="user-id">
                        {translate('Player ID:')}
                        {user?.idReferral}
                      </span>
                    </div>

                    <div className="cell gender-name-wrap">
                      <div className="gender">
                        <label className="label">{translate('Title')}</label>
                        <Select options={titlesTranslated} value={title} onChange={setTitle} />
                      </div>
                      <div className="name">
                        <Input
                          value={user?.name}
                          labelName={translate('First Name*')}
                          type="text"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="cell surname">
                      <Input
                        value={user?.lname}
                        disabled
                        labelName={translate('Last Name*')}
                        type="text"
                      />
                    </div>
                    <div className="cell birth">
                      <DatePicker
                        selected={user ? new Date(user?.dob) : null}
                        onChange={(date) => {

                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        minDate={subYears(new Date(), 82)}
                        maxDate={subYears(new Date(), 18)}
                        placeholderText={translate('Day / Month / Year')}
                        dropdownMode="select"
                        customInput={<CustomDate />}
                      />
                    </div>

                  </div>
                  <div className="contact-block">
                    <div className="title">
                      {translate('Contact Info')}
                    </div>
                    <div className="cell email">
                      <Input value={user?.email} disabled labelName="Email*" type="text" />
                    </div>
                    <div className="cell code-number-wrap">
                      <div className="code">
                        <label className="label">{translate('Country Code')}</label>
                        <Select
                          options={optionsPhone}
                          outerTop
                          value={{
                            value: countryCode?.code,
                            label: `${countryCode?.code} ${countryCode?.name}`,
                          }}
                          onChange={handleCountryCodeChange}
                        />
                      </div>
                      <div className="number">
                        <Input
                          labelName={translate('Phone Number*')}
                          value={phone}
                          maxLength={12}
                          onlyNumbers
                          onChange={setPhone}
                          error={errors.phone}
                          validateField={validateField}
                          name="phone"
                          placeholder="321 555 1212"
                        />
                      </div>
                    </div>
                    <div className="cell timezone">
                      <label className="label">{translate('Timezone')}</label>
                      <Select options={timezonesOptions} value={userTimezone} onChange={handleUserTimezone} />
                    </div>
                  </div>
                </div>
                <div className="address-security-block">
                  <div className="address-block">
                    <div className="title">{translate('Address')}</div>
                    <div className="cell street-wrap">
                      <div className="street">
                        <Input
                          labelName={translate('Street*')}
                          value={street}
                          validateField={validateField}
                          maxLength={255}
                          onChange={setStreet}
                          error={`${errors.street}`}
                          errorNum={errors.number}
                          name="street"
                          placeholder={translate('Some Street')}
                        />
                      </div>
                      <div className="number">
                        <Input
                          labelName={translate('No.*')}
                          value={numb}
                          maxLength={255}
                          onChange={setNumb}
                          // onlyNumbers
                          error={errors.number}
                          validateField={validateField}
                          disableError
                          name="number"
                          placeholder={123}
                        />
                      </div>
                    </div>
                    <div className="cell postcode-city-wrap">
                      <div className="postcode">
                        <Input
                          value={postCode}
                          labelName={translate('Post Code*')}
                          type="text"
                          // onlyNumbers
                          error={errors.postCode}
                          maxLength={255}
                          placeholder="12345"
                          validateField={validateField}
                          name="postcode"
                          onChange={setPostCode}
                        />
                      </div>
                      <div className="city">
                        <Input
                          labelName={translate('City*')}
                          type="text"
                          error={errors.city}
                          value={city}
                          maxLength={255}
                          placeholder="Rivendell"
                          validateField={validateField}
                          name="city"
                          onChange={setCity}
                        />
                      </div>
                    </div>
                    <div className="cell optional-address">
                      <Input
                        labelName={translate('Optional Address')}
                        value={adress}
                        name="adress"
                        error={errors.adress}
                        onChange={setAdress}
                        placeholder="Esgaroth"
                        validateField={validateField}
                        maxLength={255}
                        type="text"
                      />
                    </div>
                    <div className="cell country">
                      <label className="label">{translate('Country')}</label>
                      <Select
                        options={options}
                        value={{
                          value: country?.shortName,
                          label: country?.name,
                        }}
                        onChange={handleCountryChange}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="security-wrap">
                    <div className="security">
                      <div className="wrap">
                        <div className="img-wrap">
                          <img src={lock} alt="" />
                        </div>
                        <div className="text">
                          <p>{translate('Security')}</p>
                          <a href="" onClick={handleResetPassword}>{translate('Change password')}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-wrap">
              {loading ? <img src={spinner} alt="" className="spinner-account-wrapper" />
                : (
                  <button
                    className={classNames('save blue-filled-btn save-account', {
                      disabled: loading || !isInvalid() || disableTopButton,
                    })}
                    type="submit"
                    disabled={loading}
                  >
                    {translate('Save Settings')}
                  </button>
                )}
            </div>
          </form>
        </div>
        <form className="settings" onSubmit={handleSettingSubmit}>
          <div className="settings-block">
            <div className="notifications-block">
              <div className="heading-page">
                {translate('Notification Settings')}
              </div>
              <ul>
                <li>
                  <Checkbox value={emailNotification === 0} onChange={() => setEmailNotification(0)}>
                    <h3>
                      {' '}
                      {translate("Email - don't miss a thing!")}
                    </h3>
                    <p>
                      {translate('NotificationSettingsText1')}
                      {' '}
                    </p>
                  </Checkbox>
                </li>
                <li>
                  <Checkbox value={emailNotification === 20} onChange={() => setEmailNotification(20)}>
                    <h3>{translate('Email - account details only!')}</h3>
                    <p>{translate('NotificationSettingsText2')}</p>
                  </Checkbox>
                </li>
              </ul>
            </div>
            <div className="deposit-block">
              <div className="heading-page">{translate('Deposit Limit')}</div>
              <ul>
                <li>
                  <Checkbox value={!depositLimit} onChange={() => setDepositLimit(false)}>
                    <h3>{translate('No Deposit Limit')}</h3>
                  </Checkbox>
                </li>
                <li>
                  <Checkbox value={depositLimit} onChange={() => setDepositLimit(true)}>
                    <h3>{translate('Set Deposit Limit')}</h3>
                  </Checkbox>
                </li>
              </ul>
              {depositLimit && (
              <div className="cell deposit form">
                <Input
                  labelName={translate('Deposit Limit')}
                  value={deposit}
                  maxLength={5}
                  maxValue={50000}
                  onlyNumbers
                  onChange={setDeposit}
                  error={errors.deposit}
                  validateField={validateField}
                  name="deposit"
                  placeholder="€200"
                />
              </div>
              )}
            </div>
          </div>
          <div className="responsible-gaming-block">
            <a
              href=""
              className="title"
              onClick={(e) => {
                e.preventDefault();
                setShowAccountGaming(!showAccountGaming);
              }}
            >
              [
              <span>{showAccountGaming ? '—' : '+'}</span>
              ]
              {' '}
              {translate('Account and Responsible Gaming Status')}
            </a>
            {showAccountGaming ? (
              <div className="responsible-gaming-content">
                <ul>
                  <li>
                    <Checkbox value={Number(status) === 10} onChange={() => setStatus(10)}>
                      <h3>
                        {' '}
                        {translate('Registered')}
                      </h3>
                      <p>{translate('You are registered')}</p>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox value={Number(status) === 20} onChange={() => setStatus(20)}>
                      <h3>{translate('Temporary Suspension')}</h3>
                      <p>{translate('TemporaryText1')}</p>
                      <p>{translate('Choose how long you would like to suspend access to your account:')}</p>
                      <Select options={temporaryOptionsTranslated} value={temporaryCurrent} onChange={setTemporaryCurrent} />
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox value={Number(status) === 30} onChange={() => setStatus(30)}>
                      <h3>{translate('Self Exclusion')}</h3>
                      <p>
                        {translate('Self Exclusion Text1')}
                      </p>
                      <p>
                        {translate('Self Exclusion Text2')}
                      </p>
                      <Select options={selfExclusionTranslated} value={selfCurrent} onChange={setSelfCurrent} />
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox value={Number(status) === 0} onChange={() => setStatus(0)}>
                      <h3>{translate('Close Account')}</h3>
                      <p>{translate('Warning')}</p>
                    </Checkbox>
                  </li>
                </ul>
              </div>
            ) : null }
          </div>
          <div className="btn-wrap">
            {settingLoading ? <img src={spinner} alt="" className="spinner-account-wrapper" /> : (
              <button
                className={classNames('save blue-filled-btn', {
                  disabled: settingLoading || disableBottomButton || deposit.trim().length === 0 || errors.deposit.length,
                })}
                type="submit"
              >
                {translate('Save Settings')}
              </button>
            )}
          </div>

        </form>
      </div>
    </div>
  );
};

export default injectIntl(memo(Index));
