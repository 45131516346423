import React, { useState, useContext, useEffect } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"
import classNames from "classnames"
import "./transactions.scss"
import { transformPrice } from "../../../utils/formattes"
import ApplicationContext from "../../../state/ApplicationContext"

const funds = [1000, 2000, 5000]

const Transactions = ({
  intl: { formatMessage },
  setCurrentPage,
  currentPage,
}) => {
  const { isDesktop, user } = useContext(ApplicationContext)
  const [selected, setSelected] = useState(0)
  const [value, setValue] = useState("")

  const handleChange = event => {
    console.log(event.target.value)
    const newValue = event.target.value.replace(/\D/g, "")
    setValue(newValue)
  }
  const priceTransformDesktop = isDesktop ? "forCart" : "priceWithCurrency"

  const translate = str => formatMessage({ id: str })

  useEffect(() => {
    setCurrentPage(currentPage)
  }, [currentPage, setCurrentPage])

  return (
    <div className="transactions-wrapper">
      <Helmet>
        <title>Lotteroo | {translate("My Transactions")}</title>
      </Helmet>
      <div className="balance-block">
        <ul>
          <li>
            <div className="amount">
              {transformPrice(user?.depositBalance || 0, "forCart")}
            </div>
            <div className="title">
              {formatMessage({ id: "Available balance" })}
            </div>
            <div className="type deposit">
              {formatMessage({ id: "Deposit" })}
            </div>
          </li>
          {/* <li className={'divider'}> */}

          {/* </li> */}
          <li>
            <div className="amount">
              {transformPrice(user?.withdrawalBalance || 0, "forCart")}
            </div>
            <div className="title">
              {formatMessage({ id: "Withdrawable balance" })}
            </div>
            <div className="type withdraw">
              {formatMessage({ id: "Withdraw" })}
            </div>
          </li>
        </ul>
      </div>
      <div className="page-content">
        <div className="transactions-block" />
        <div className="add-funds-block">
          <div className="heading-page">
            {formatMessage({ id: "Add Funds" })}
          </div>
          <div className="clear" />
          <ul>
            {funds.map((element, index) => {
              return (
                <li
                  className={classNames({
                    active: selected === index,
                  })}
                  onClick={() => setSelected(index)}
                >
                  <span>{transformPrice(element, priceTransformDesktop)}</span>
                </li>
              )
            })}
            <li
              className={classNames("custom form", {
                active: selected < 0,
              })}
              onClick={() => setSelected(-1)}
            >
              <div className={"text"}>
                {" "}
                {formatMessage({ id: "Custom amount" })}
              </div>
              <input
                type="text"
                className="input-number"
                placeholder="0"
                maxLength={10}
                value={value}
                onChange={handleChange}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Transactions)
