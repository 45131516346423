import React, { FunctionComponent, ReactNode, memo } from 'react';
import classNames from 'classnames';
import style from './checkbox.module.scss';

interface CheckBoxProps {
  id: string;
  name: string;
  className?: string;
  value: string;
  onChange: () => {};
  optionText?: string;
  children?: ReactNode;
  label?: string;
}

const Checkbox: FunctionComponent<CheckBoxProps> = ({
  id, name, className, children, value, onChange, optionText, label, ...props
}) => (
  <div className={style.checkBoxWrapper}>
    <div className="input-wrap" onClick={onChange}>
      <input
        type="radio"
        className={classNames('custom-input', className)}
        id={id}
        name={name}
        value={value}
        checked={value}
        onChange={onChange}
        {...props}
      />
      <label htmlFor={id} className="label">{label}</label>
    </div>
    <div className="option">
      {children}
    </div>
  </div>
);

export default memo(Checkbox);
