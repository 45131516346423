import React, {memo} from 'react';
import classNames from 'classnames';
import style from './alert.module.scss';
import ModalWrapperComponent from "../ModelWrapper"


const Alert = ({
  alertHeading, descriptionText, confirmText, cancelText, onSubmit, onClose, show, disabled
}) => {
  if(!show) return null;
  return (
  <ModalWrapperComponent>
    <div className={style.alertWrapper}>
    <div className={style.alertOverlay} />
    <div className={style.alertContent}>
      <div className={style.closeBtn} onClick={onClose}>
        <svg
          id="SVGDoc"
          width="12"
          height="12"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 12 12"
        >
          <g opacity="0.33">
            <path
              d="M11.69004,1.80769v0l-4.19254,4.19261v0l4.19254,4.19229v0v0c0.19157,0.1917 0.31002,0.45647 0.31002,0.74872c0,0.58489 -0.4739,1.05894 -1.05881,1.05894c-0.2924,0 -0.5571,-0.11845 -0.74867,-0.31047v0v0l-4.19243,-4.19204v0l-4.19243,4.19204v0v0c-0.19169,0.19202 -0.45639,0.31047 -0.74879,0.31047c-0.58468,0 -1.05881,-0.47404 -1.05881,-1.05894c0,-0.29225 0.11845,-0.55702 0.31013,-0.74872v0v0l4.19254,-4.19229v0l-4.19254,-4.19261v0v0c-0.19169,-0.1917 -0.31013,-0.4564 -0.31013,-0.74872c0,-0.58464 0.47413,-1.05887 1.05881,-1.05887c0.2924,0 0.5571,0.11845 0.74879,0.31041v0v0l4.19243,4.19229v0l4.19243,-4.19229v0v0c0.19157,-0.19196 0.45628,-0.31041 0.74867,-0.31041c0.58491,0 1.05881,0.47424 1.05881,1.05887c0,0.29232 -0.11845,0.55702 -0.31002,0.74872z"
              fill="#1b1b1b"
              fillOpacity="1"
            />
          </g>
        </svg>
      </div>
      <div className={style.alertContentHeading}>
        {alertHeading}
      </div>
      <div className={style.alertDescriptionText}>
        {descriptionText}
      </div>

      <div className={style.buttonWrapper}>
        <div className={classNames(style.button, style.outline, {
          [style.disabled]: disabled
        })} onClick={onSubmit}>{confirmText}</div>
        <div className={classNames(style.button, {
          [style.disabled]: disabled
        })} onClick={onClose}>{cancelText}</div>
      </div>
    </div>
  </div>
  </ModalWrapperComponent>
)};

export default memo(Alert);
