import EXIF from 'exif-js';

const orientateCanvas = (context, img, orientation) => {
  context.canvas.width = img.naturalWidth;
  context.canvas.height = img.naturalHeight;
  switch (orientation) {
    case 2:
      // horizontal flip
      context.translate(context.canvas.width, 0);
      context.scale(-1, 1);
      break;
    case 3:
      // 180° rotate left
      context.translate(context.canvas.width, context.canvas.height);
      context.rotate(Math.PI);
      break;
    case 4:
      // vertical flip
      context.translate(0, context.canvas.height);
      context.scale(1, -1);
      break;
    case 5:
      // vertical flip + 90 rotate right
      context.canvas.height = img.naturalWidth;
      context.canvas.width = img.naturalHeight;
      context.rotate(0.5 * Math.PI);
      context.scale(1, -1);
      break;
    case 6:
      // 90° rotate right
      context.canvas.height = img.naturalWidth;
      context.canvas.width = img.naturalHeight;
      context.rotate(0.5 * Math.PI);
      context.translate(0, -context.canvas.width);
      break;
    case 7:
      // horizontal flip + 90 rotate right
      context.rotate(0.5 * Math.PI);
      context.canvas.height = img.naturalWidth;
      context.canvas.width = img.naturalHeight;
      context.translate(context.canvas.height, -context.canvas.width);
      context.scale(-1, 1);
      break;
    case 8:
      // 90° rotate left
      context.canvas.height = img.naturalWidth;
      context.canvas.width = img.naturalHeight;
      context.rotate(-0.5 * Math.PI);
      context.translate(-context.canvas.height, 0);
      break;
  }
  const outputImageAspectRatio = 1;
  const inputWidth = img.naturalWidth;
  const inputHeight = img.naturalHeight;
  const inputImageAspectRatio = inputWidth / inputHeight;
  let outputWidth = inputWidth;
  let outputHeight = inputHeight;
  if (inputImageAspectRatio > outputImageAspectRatio) {
    outputWidth = inputHeight * outputImageAspectRatio;
  } else if (inputImageAspectRatio < outputImageAspectRatio) {
    outputHeight = inputWidth / outputImageAspectRatio;
  }
  const outputX = (outputWidth - inputWidth) * 0.5;
  const outputY = (outputHeight - inputHeight) * 0.5;
  console.log(outputWidth, outputHeight, outputX, outputY);
  context.canvas.width = 100;
  context.canvas.height = 100;
  let dw = 100;
  let dh = 100;
  let dx = 0;
  let dy = 0;

  if (outputY !== 0) {
    dh = 100 * inputHeight / inputWidth;
    dy = (100 - dh) / 2;
  }

  if (outputX !== 0) {
    dw = 100 * inputWidth / inputHeight;
    dx = (100 - dw) / 2;
  }
  context.drawImage(img, dx, dy, dw, dh);
  return context;
};
const checkImageOrientation = (img) => new Promise((resolve) => {
  let result = null;
  EXIF.getData(img, function () {
    const orientation = EXIF.getTag(this, 'Orientation');
    let context = document.createElement('canvas').getContext('2d');
    context = orientateCanvas(context, img, orientation);
    result = context.canvas.toDataURL();
    resolve(result);
  });
});
const getImageData = async (imageSCR) => {
  const promise = new Promise(async (resolve, reject) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(imageSCR);

    img.onload = async () => {
      console.log(img.width, img.height)
      if(img.width < 100 || img.height < 100){
        resolve({ error: true});
        return;
      }
      const image = await checkImageOrientation(img);
      const imageAfterRotate: any = new Image();
      imageAfterRotate.src = image;
      imageAfterRotate.onload = () => {
        const width = imageAfterRotate.naturalWidth;
        const height = imageAfterRotate.naturalHeight;
        const source = image;
        resolve({ width, height, source });
      };
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
  return promise;
};

const bytesToMegaBytes = (bytes) => {
  console.log(bytes);
  return bytes / (1024 * 1024);
};

export {
  getImageData,
  bytesToMegaBytes,
};
