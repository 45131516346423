import React, { useContext, useEffect, useState } from 'react';
import ApplicationContext from '../../state/ApplicationContext';
import { Link } from 'gatsby';
import './tickets-panel.scss';
import { injectIntl } from 'gatsby-plugin-intl';
import noTickets from '../../assets/images/no-tickets.png';
import { getTickets, updateTicket } from '../../api';
import Ticket from '../Cards/Ticket';
import spinner from '../../assets/images/spinner.gif';
import Alert from '../Modals/Alert';
import CartContext from "../../state/CartContext"
import {newTicket} from "../../utils/ticketsUtils"
import classNames from 'classnames';

const toggleArrayValue = (array, value) => {
  const newArray = [...array];
  const index = newArray.indexOf(value);

  if (index === -1) {
    newArray.push(value);
    return newArray;
  }
  newArray.splice(index, 1);
  return newArray;
};

/**
 *
 * @param formatMessage
 * @param props
 * @constructor
 */
const TicketsPanel = ({ intl: { formatMessage, locale }, ...props }) => {
  const {
    handleGrowl,
  } = useContext(ApplicationContext);
  const {
    updateCartItems,
  } = useContext(CartContext);

  const currentLocale = locale === 'en' ? '': locale

  const [ticketQueue, setTicketQueue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingSubscribe, setLoadingSubscribe] = useState(false);
  const [ticketsIsLoading, setTicketIsLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [activePrint, setActivePrint] = useState(-1);
  const [hideInactive, setHideInactive] = useState(false);
  const [wathedItem, setWatcherItem] = useState([]);
  useEffect(() => {
    getTickets(30, 1).then((response) => {
      setTickets(response.data.data);
      setNextPage(2);
      setLoading(false);
    });
  }, []);


  const hideInactiveTickets = () => {
    setHideInactive(!hideInactive);
  };

  const handleSubmitAlert = () => {
    setLoadingSubscribe(true);
    updateTicket(ticketQueue.id).then((response) => {
      const newTickets = tickets.map((element) => {
        if (element.id === response.data.id) {
          return {
            ...element,
            active: false,
          };
        }
        return element;
      });
      setTickets(newTickets);
      setTicketQueue(null);
    }).finally(() => {
      setLoadingSubscribe(false);
    });
  };

  const handleCancelAlert = () => {
    setTicketQueue(null);
  };

  const onWatchClick = (index) => {
    setWatcherItem(toggleArrayValue(wathedItem, index));
  };

  const onPrintClick = (index) => {
    setActivePrint(index);

    setTimeout(() => {
      window.print();
    }, 500);
  };

  const onCartAddClick = (element) => {
    const cartItems = localStorage.getItem('cartItems');
    const createdNewTicket = newTicket(element.game);
    createdNewTicket.mainNumbers = element.mainNumbers;
    createdNewTicket.bonusNumbers = element.bonusNumbers;
    createdNewTicket.idGame = element.game.id;
    if (cartItems) {
      const parsedCart = JSON.parse(cartItems);
      localStorage.setItem('cartItems', JSON.stringify([...parsedCart, createdNewTicket]));
    }

    if (!cartItems) {
      localStorage.setItem('cartItems', JSON.stringify([element]));
    }

    updateCartItems();
    handleGrowl(true, formatMessage({id: 'Ticket is added to cart successfully'}), true);
  };
  const filteredElements = tickets.filter((filteredElements) => {
    if (hideInactive && !filteredElements.active) return false;
    return true;
  });


  const handleShowMore = () => {
    setTicketIsLoading(true)
    getTickets(30, nextPage).then((response) => {
      console.log(tickets)
      setTickets([...tickets, ...response.data.data]);
      if(response.data.links.next){
        setNextPage(nextPage + 1);
      } else {
        setNextPage(null)
      }
      setLoading(false);
    }).finally(() => {
      setTicketIsLoading(false);
    });
  }

  if (loading) {
    return (
      <div className={'ticket-panel-main-container'}>
      <div className="tickets-panel">
        <div className="spinner-wrapper">
          <img src={spinner} alt="" />
        </div>
      </div>
      </div>
    );
  }
  return (
    <div className={'ticket-panel-main-container'}>
    <div className="tickets-panel">
      {tickets.length
        ? (
          <div className="tickets-panel-list-wrapper">
            <div className="hide">
              <input type="checkbox" className="custom-input" id="hide" onChange={hideInactiveTickets} />
              <label htmlFor="hide" className="label" />
              <label htmlFor="hide" className="input-label">{formatMessage({ id: 'Hide Inactive Tickets' })}</label>
            </div>
            {filteredElements.length ? <div className="list">
              <ul>
                <li className="block title-block">
                  <div className="ticket-row title-row">
                    <div className="logo">{formatMessage({ id: 'Game' })}</div>
                    <div className="title" />
                    <div className="order-num">{formatMessage({ id: 'Ticket id' })}</div>
                    <div className="frequency">{formatMessage({ id: 'Duration' })}</div>
                    <div className="amount">{formatMessage({ id: 'Winnings' })}</div>
                    <div className="actions" />
                  </div>
                </li>

                {filteredElements.map((element, index) => (
                  <Ticket
                    element={element}
                    key={element.id}
                    onStopSubscription={setTicketQueue}
                    onWatchClick={onWatchClick}
                    wathedItem={wathedItem}
                    onPrintClick={onPrintClick}
                    onCartAddClick={onCartAddClick}
                    index={index}
                    activePrint={activePrint}
                  />
                ))}
              </ul>
              {nextPage && <div className={'show-more-button-wrapper'}>
                {ticketsIsLoading ? <img src={spinner} alt=""/>:<button onClick={handleShowMore} className={classNames('show-more-button', {
                  disabled: ticketsIsLoading
                })}>{formatMessage({ id: 'Show more' })}</button>}
              </div>}
            </div> : <div className="no-tickets">
              <img src={noTickets} alt="No tickets" />
              <div className="no-tickets-text">{formatMessage({ id: "You don't have active tickets" })}</div>
            </div>}
          </div>
        ) : (
          <div className="no-tickets">
            <img src={noTickets} alt="No tickets" />
            <div className="no-tickets-text">{formatMessage({ id: 'No Tickets Text' })}</div>
            <Link to={`${currentLocale ? '/' + currentLocale + '/': '/'}`}>
              <button className="green-btn">
                {formatMessage({ id: 'Browse Games' })}
                {' '}
              </button>
            </Link>
          </div>
        )}

      <Alert
        onSubmit={handleSubmitAlert}
        onClose={handleCancelAlert}
        show={Boolean(ticketQueue)}
        disabled={loadingSubscribe}
        confirmText={formatMessage({ id: 'Yes' })}
        cancelText={formatMessage({ id: 'No' })}
        alertHeading={formatMessage({ id: 'Confirmation' })}
        descriptionText={formatMessage({ id: 'Are you sure that you want to stop the subscription?' })}
      />
    </div></div>
  );
};

export default injectIntl(TicketsPanel);
