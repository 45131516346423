import React, {
  useContext, useEffect, useState,
} from 'react';
import './my-tickets.scss';
import Loadable from 'react-loadable';
import classNames from 'classnames';
import {injectIntl} from "gatsby-plugin-intl"
import { Helmet } from 'react-helmet';
import TicketsPanel from '../../tickets-panel';
import ApplicationContext from '../../../state/ApplicationContext';
import spinner from '../../../assets/images/spinner.gif';


const Preloader = () => (
  <div className="tickets-panel">
    <div className="spinner-wrapper">
      <img src={spinner} alt="" />
    </div>
  </div>
);

const Schedule = Loadable({
  loader: () => import('../../Schedule'),
  loading: () => <Preloader />,
});

const MobileSchedule = Loadable({
  loader: () => import('../../MobileSchedule'),
  loading: () => <Preloader />,
});

const MyTickets = ({ setCurrentPage, currentPage,  intl: { formatMessage } }) => {
  const [tab, setTab] = useState('tickets');
  const { isDesktop } = useContext(ApplicationContext);
  const translate = (str) => (formatMessage({id: str}))
  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage, setCurrentPage]);

  const handleTicketsTab = () => {
    if (tab !== 'tickets') {
      setTab('tickets');
    }
  };

  const handleDrawsTab = () => {
    if (tab !== 'draws') {
      setTab('draws');
    }
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>Lotteroo | {translate('My Tickets')}</title>
      </Helmet>
      <div className="tab-container">
        <div className="tab-header">
          <div
            className={classNames('tab-element', {
              active: tab === 'tickets',
            })}
            onClick={handleTicketsTab}
          >
            {translate('Tickets')}
          </div>
          <div
            className={classNames('tab-element', {
              active: tab === 'draws',
            })}
            onClick={handleDrawsTab}
          >
            {translate('Draws')}
          </div>
        </div>

        <div className="tab-content">
          <div className={classNames('tickets-panel-wrapper', {
            active: tab === 'tickets',
          })}
          >
            <TicketsPanel />
          </div>
          <div className={classNames('draws-panel-wrapper', {
            active: tab === 'draws',
          })}
          >
            {tab === 'draws' && (
            <>
              {isDesktop ? <Schedule PreloadComponent={Preloader} /> : <MobileSchedule PreloadComponent={Preloader} />}
            </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(MyTickets);
