import React, { memo } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'gatsby-plugin-intl';
import { transformPrice } from '../../../utils/formattes';
import { transform } from '../../../utils/dateFormatted';
import removeIcon from '../../../assets/icons/not-interested.svg';


const colors = {
  powerball: {
    6: {
      name: 'powerball',
      color: '#FFF',
      background: '#F00',
    },
  },
  'el-gordo': {
    6: {
      name: 'C',
      color: '#fff',
      background: '#70e43f',
    },
    7: {
      name: 'R',
      color: '#fff',
      background: '#34a9ff',
    },
  },
  'euro-millions': {
    6: {
      name: 'lucky stars',
      color: '#fff',
      background: '#2ebe57',
    },
    7: {
      name: 'lucky stars',
      color: '#fff',
      background: '#ff451a',
    },
  },
  'mega-millions': {
    6: {
      name: 'lucky stars',
      color: '#fff',
      background: '#2ebe57',
    },
    7: {
      name: 'lucky stars',
      color: '#fff',
      background: '#ff451a',
    },
  },
  'euro-jackpot': {
    6: {
      name: 'Euro number',
      color: '#fff',
      position: 6,
      background: '#e3b82d',
    },
    7: {
      name: 'Euro number',
      color: '#fff',
      position: 7,
      background: '#e3b82d',
    },
  },
  '6aus49': {
    6: {
      name: 'super',
      color: '#fff',
      background: '#e3d759',
    },
  },
  'super-enalotto': {
    7: {
      name: 'jolly',
      color: '#fff',
      background: '#3ec1ec',
    },
    8: {
      name: 'superstar',
      color: '#fff',
      background: '#e51522',
    },
  },
  'mega-sena': {},
  'la-primitiva': {
    7: {
      name: 'bonus number',
      color: '#fff',
      background: '#fe6f0f',
    },
    8: {
      name: 'refund',
      color: '#fff',
      background: '#3b9c03',
    },
    9: {
      name: 'joker',
      color: '#fff',
      background: '#fe6f0f',
    },
  },
};

const Ticket = ({
  element, onStopSubscription, onWatchClick, onPrintClick, onCartAddClick, wathedItem, index, intl: { formatMessage }, activePrint,
}) => {
  const amountWon = element.draws.reduce((acc, draw) => acc + (draw.prize || 0), 0);

  let draws = null;

  if (element.toPlay === 1) {
    draws = formatMessage({ id: '1 draws' });
  }

  if (element.toPlay > 1) {
    draws = formatMessage({ id: 'Draws count' }, {
      count: element.toPlay,
    });
  }

  const translate = (str) => (formatMessage({id: str}))

  let transformedDate = transform(element.draws[0]?.date, 'fullDate');
  if(transformedDate) {
    const splittedate = transformedDate.split(', ');
    const day = translate(splittedate[0]);
    const dateAfterDay = splittedate[1].split(' ');
    dateAfterDay[1] = translate(dateAfterDay[1]);

    const newTranslatedDate = day + ', ' + dateAfterDay.join(' ');
    transformedDate = newTranslatedDate;
  }
  return (
    <li className={classNames('block', {
      inactive: !element.active,
      print: activePrint === index,
      selected: wathedItem.includes(element.id),
    })}
    >
      <div className="ticket-row">
        <div className="logo">
          <div className="img-wrap">
            <img src={element.game.logoImgUrl} alt={element.game.lotteryTitle} />
          </div>
        </div>
        <div className="title">
          {element.game.lotteryTitle}
        </div>
        <div className="order-num">
          {element.id.toUpperCase()}
        </div>
        <div className="frequency">
          {draws}
        </div>
        <div className="amount">
          {transformPrice(amountWon, 'forCart')}
        </div>
        <div className="actions">
          <button disabled={!element.count} onClick={() => onStopSubscription(element)} className={'remove-button-ticket'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path id="not-interested" d="M10,20A10,10,0,1,1,20,10,10,10,0,0,1,10,20ZM2,10a7.991,7.991,0,0,0,12.9,6.314L3.686,5.1A7.956,7.956,0,0,0,2,10Zm8-8A7.956,7.956,0,0,0,5.1,3.686L16.314,14.9A7.991,7.991,0,0,0,10,2Z" />
            </svg>
          </button>
          <button
            onClick={() => onWatchClick(element.id)}
            className={classNames({
              active: wathedItem.includes(element.id),
            })}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
              <path
                id="visibility"
                className="cls-1"
                d="M11,15C1.6,15,0,8,0,8S1.6,1,11,1,22,8,22,8,20.4,15,11,15ZM11,3a5,5,0,1,0,5,5A5,5,0,0,0,11,3Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,11,11Z"
                transform="translate(0 -1)"
              />
            </svg>
          </button>
          <button onClick={() => onPrintClick(index)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
              <path
                id="print"
                className="cls-1"
                d="M16,14v4H4V14H0V8A3,3,0,0,1,3,5H17a3,3,0,0,1,3,3v6H16Zm-2,0H6v2h8V14Zm4-7H16V9h2V7ZM4,0H16V4H4V0Z"
              />
            </svg>

          </button>
          <button disabled={element.active} onClick={() => onCartAddClick(element)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.875" height="21" viewBox="0 0 19.875 21">
              <path
                id="add-shopping-cart"
                className="cls-1"
                d="M16,11.266L15,12H7.5l-0.334.445-0.024.069L7.13,12.494,6,14H18v2H6L4,14l2.035-3.331L1.434,3H0V1H3V1.721L8,10h6.42l3.712-6.5L19.868,4.5ZM12,8H10V5H7V3h3V0h2V3h3V5H12V8ZM6,17a2,2,0,1,1-2,2A2,2,0,0,1,6,17Zm10,0a2,2,0,1,1-2,2A2,2,0,0,1,16,17Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className={classNames("info", {
        'show-info': wathedItem.includes(element.id)
      })}>
        <div className="numbers-wrap">
          <div className="numbers-row">
            <div className="ttl">{formatMessage({ id: 'Main Numbers' })}</div>
            <ul className="numbers picked-numbers main">
              {element.mainNumbers.map((num) => (<li>{num}</li>))}
            </ul>
          </div>
          <div className="numbers-row bonuses">
            <div className="ttl">{formatMessage({ id: 'Bonus Numbers' })}</div>
            <ul className="numbers picked-numbers main">
              {element.bonusNumbers.map((num, index) => (
                <li
                  title={element.game.slug}
                  style={{
                background: colors[element.game.slug]?.[element.mainNumbers.length + index + 1]?.background || 'red',
                color: colors[element.game.slug]?.[element.mainNumbers.length + index + 1]?.color || 'white',
              }}
                >
                  {num}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="draw">
            <div className="ttl">{formatMessage({ id: 'Draw' })}</div>
            <div>{formatMessage({ id: transform(element.draws[element.draws.length - 1]?.date, 'format', 'dddd') || ' '})}</div>
          </div>
          <div className="partic">
            <div className="ttl">{formatMessage({ id: 'Participation from' })}</div>
            <div>{transformedDate}</div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default injectIntl(memo(Ticket));
