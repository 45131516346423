import React, {
  useContext, useEffect, useState,
} from 'react';
import './my-account.scss';
import { Router, Redirect } from '@reach/router';
import { injectIntl } from 'gatsby-plugin-intl';
import spinner from '../../assets/images/spinner.gif';
import { navigate } from 'gatsby';
import PageTopMenu from '../../components/PageTopMenu';
import MyProfile from '../../components/pages/profile';
import MyTickets from '../../components/pages/tickets';
import MyTransactions from '../../components/pages/transactions';
import ApplicationContext from '../../state/ApplicationContext';
import {
  MY_PROFILE, MY_TICKETS, PROFILE, TICKETS, MY_ACCOUNT, TRANSACTIONS, MY_TRANSACTIONS,
} from '../../contants/routes';

const TopBlockComponent = ({
  currentPage, pages, children, locale, formatMessage,
}) => {
  const [isWindow, setIsWindow] = useState(false);
  useEffect(() => {
    setIsWindow(true)
  }, [])
  return (
  <>
    <div className="top-block-profile">
      <div className="title">{formatMessage({ id: 'My Account' })}</div>
    </div>
    <PageTopMenu currentPage={currentPage} pages={pages} locale={locale} />
    {children}
    {/*{!isWindow ? <div className={'server-loading'} style={{*/}
    {/*  display: 'flex',*/}
    {/*  justifyContent: 'center',*/}
    {/*  alignItems: 'center',*/}
    {/*  minHeight: 200*/}
    {/*}}><img src={spinner} alt=""/></div>: null}*/}
  </>
)};
const pages = [
  {
    label: 'My Profile',
    url: MY_PROFILE,
  },
  {
    label: 'My Tickets',
    url: MY_TICKETS,
  },
  {
    label: 'My Transactions',
    url: MY_TRANSACTIONS,
  },
];
const MyAccount = (props) => {
  const {
    handleGrowl, setShowSignIn, user,
  } = useContext(ApplicationContext);

  const homePageUrl = props.intl.locale === 'en' ? '/' : `/${props.intl.locale}/`;
  const currentLocale = props.intl.locale === 'en' ? '' : `/${props.intl.locale}`;

  if (typeof window !== 'undefined') {
    const userData = localStorage.getItem('userData');
    if (!userData) {
      // handleGrowl(true, props.intl.formatMessage({
      //   id: 'To view this page, you must log in to your account',
      // }), false);
      navigate(homePageUrl);
      setShowSignIn(true);
      return null;
    }
  }

  const [currentPage, setCurrentPage] = useState(MY_ACCOUNT);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // navigate('/my-account/my-profile');
    }
  }, []);

  const locale = props.intl.locale === 'en' ? '' : props.intl.locale;
  if (props.location.pathname === props.uri + PROFILE
    || props.location.pathname === props.uri + TICKETS
    || props.location.pathname === props.uri + TRANSACTIONS || typeof window === 'undefined') {
    return (
      <div className="my-account-main-wrapper">
        <Router>
          <TopBlockComponent currentPage={currentPage} pages={pages} path={props.uri} locale={locale} formatMessage={props.intl.formatMessage}>
            <MyProfile path={PROFILE} setCurrentPage={setCurrentPage} currentPage={MY_PROFILE} />
            <MyTickets path={TICKETS} setCurrentPage={setCurrentPage} currentPage={MY_TICKETS} />
            <MyTransactions path={TRANSACTIONS} setCurrentPage={setCurrentPage} currentPage={MY_TRANSACTIONS} />
          </TopBlockComponent>
        </Router>
      </div>
    );
  }
  if (typeof window !== 'undefined') {
    navigate(`${currentLocale}/404`);
  }
  return null;
};

export default injectIntl(MyAccount);
